.billing-section {
    margin: 0 15px;
}
.billing-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.billing-img {
    width: 220px;
    height: 150px;
    margin-top: 50px;
}

.billing-table {
    width: 100%;
}

.billing-title {
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.billing-payment-value {
    font-size: 15px;
    font-weight: bold;
    margin: 10px 0;
}

.billing-select {
    width: 300px;
}

.billing-action {
    margin: 8px 0;
    display: flex;
    gap: 10px;
    align-items: center;
}

.billing-div-customer {
    border: 1px solid rgb(225,225,225);
    border-radius: 10px;
    padding: 10px 0;
    width: 100%;
}

.item-div-billing {
    margin-left: 10px;
}

@media (max-width:600px) {
.billing-img {
    width: 150px;
    height: 100px;
    margin-top: 50px;
}
}