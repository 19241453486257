.sidebar-logo {
    width: 100%;
    display: flex;
    justify-content: center;
}
.sidebar-icon {
    width: 90px;
    height: 60px;
    margin: 20px 0;
    transition: 0.5s ease all;

}
.sidebar-icons {
    width: 60px;
    height: 40px;
    margin: 20px 0;
    transition: 0.5s ease all;

}
.siders {
    transition: 0.5s all ease;
    overflow-y: auto;
}