body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
  color: black;
}
.barside {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: scroll;
  z-index: 1000;
}
.barside::-webkit-scrollbar {
  width: 0;
}
.home-layout {
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 40vw;
}
.welcome {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
}
.user-Name {
    text-align: center;
    font-weight: normal;
    font-size: 1.5rem;
}
.email-User {
    text-align: center;
    font-weight: normal;
    font-size: 1.5rem;
    margin-top: 40px;
}
.payments-flex {
    display: flex;
    gap: 5px;
    align-items: center;
}
.payments-content {
    margin: 5px 20px;
    width: 100%;
}
.payments-data-filter {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.infocust-content {
    margin: 5px 20px;
}
.infocust-filter {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.infocust-flex {
    display: flex;
    gap: 5px;
}
.customerBtn {
    display: flex;
    gap: 5;
    margin: 10px 0;
}
.sidebar-logo {
    width: 100%;
    display: flex;
    justify-content: center;
}
.sidebar-icon {
    width: 90px;
    height: 60px;
    margin: 20px 0;
}
.siders {
    transition: 0.5s all ease;
    overflow-y: auto;
}
.statistic {
    width: 100%;
    margin-bottom: 10px;
}
.collapse {
    width: calc(100%);
}
.layout-dashboardFinance {
    min-height: 500px;
    margin: 0 5px;
}
.material-flex {
    display: flex;
    gap: 5px;
}
.billing-section {
    margin: 0 15px;
}
.billing-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.billing-img {
    width: 220px;
    height: 150px;
    margin-top: 50px;
}

.billing-table {
    width: 100%;
}

.billing-title {
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.billing-payment-value {
    font-size: 15px;
    font-weight: bold;
    margin: 10px 0;
}

.billing-select {
    width: 200px;
}

.billing-action {
    margin: 15px 0;
    display: flex;
    gap: 10px;
    align-items: center;
}

@media (max-width:600px) {
.billing-img {
    width: 150px;
    height: 100px;
    margin-top: 50px;
}
}