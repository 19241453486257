.payments-flex {
    display: flex;
    gap: 5px;
    align-items: center;
}
.payments-content {
    margin: 5px 20px;
    width: 100%;
}
.payments-data-filter {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}