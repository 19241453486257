.auth-form {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./auth-wallpaper.jpg");
  background-size: cover;
  background-position: center;
  padding: 1rem;
}

.auth-container {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  padding: 2rem;
}

.auth-head {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.auth-title {
  font-weight: bold;
  font-size: 30px;
  color: white;
  margin-bottom: 0.5rem;
}

.auth-label {
  color: white;
}

.auth-button {
  width: 100%;
}

/* Responsive improvements */
@media (max-width: 768px) {
  .auth-title {
    font-size: 24px;
  }

  .auth-container {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .auth-container {
    padding: 1rem;
  }

  .auth-title {
    font-size: 20px;
  }
}
