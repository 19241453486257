.statistic {
    width: 100%;
    margin-bottom: 10px;
}
.collapse {
    width: calc(100%);
}
.layout-dashboardFinance {
    min-height: 500px;
    margin: 0 5px;
}
