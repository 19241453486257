.homeSect {
    display: flex;
}
.homeDate {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 20px 0;
}
.homeCardStyle {
    width: 100%;
}
.homeCard {
    display: flex;
    flex-direction: column;
    gap: 10%;
}